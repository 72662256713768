"use client";

import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import { useEffect } from "react";

// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#report-react-component-render-errors

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  console.log("Global error page");
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        {/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
        <NextError statusCode={0} />
      </body>
    </html>
  );
}
